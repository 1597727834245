import {
  FETCH_START,
  FETCH_SUCCESS,
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  GET_PROFILE_REQ,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_ERROR,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { AuthType } from '../../shared/constants/AppEnums';
import { toast } from 'react-toastify';
import { setInitialPath } from 'redux/actions';
import { getPrefferedCurrencySelected, setLoggedOut,getManaualSetting } from 'pages/mainPages/FreightForwarder/Admin/Quotation/redux/action';
import { saveQuotationDataReq } from 'pages/mainPages/Shipper/Quotation/redux/action';
import { useHistory } from 'react-router-dom';
import { GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD } from 'pages/mainPages/FreightForwarder/Admin/Quotation/redux/constants';

window.onbeforeunload = function (e) {
  window.localStorage.unloadTime = JSON.stringify(new Date());
};

window.onload = function () {
  let loadTime = new Date();
  let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
  let refreshTime = loadTime.getTime() - unloadTime.getTime();
  console.log(refreshTime);

  if (refreshTime > 60 * 45 * 1000) {
    //45 minutes
    toast.error('Session Expired');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userDetails');
    // window.localStorage.removeItem('unloadTime');
    window.location.reload();
  }
  if (refreshTime > 15000) {
    //3000 milliseconds
    toast.error('Session Expired');
  }
};

// FF Admin Registration with Key
export const FFAdminSignupWithKey = (data) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.post('/freightforwarder/register', data);

    if (res) {
      dispatch(fetchSuccess());
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.title);
    console.log('error!!!!', err.response.data);
    dispatch(fetchError());

    // dispatch(fetchError(err.response.data));
  }
};

// FF Admin Registration with Key
export const FFAdminSignupWithoutKey = (data) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.post('/company-register', data);

    if (res) {
      dispatch(fetchSuccess());
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  } catch (err) {
    dispatch(fetchError());
    console.log(err);
    toast.error(err.response.data.title);
    console.log('error!!!!', err.response.data);
    // dispatch(fetchError(err.response.data));
  }
};

// FFAgent Registration with Key
export const FFAgentSignupWithKey = (data) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.post('/freightforwarder/register', data);

    if (res) {
      dispatch(fetchSuccess());
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.title);
    console.log('error!!!!', err.response.data);
    // dispatch(fetchError(err.response.data));
    dispatch({ type: GET_ERROR });
    // dispatch(fetchError(err.response.data.title));
  }
};

// Airline Admin Registration with Key
export const AirAdminSignupWithKey = (data) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.post('/airline/register', data);

    if (res) {
      dispatch(fetchSuccess());
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(fetchError());
    toast.error(err.response.data.title);
    console.log('error!!!!', err.response.data);
    // dispatch(fetchError(err.response.data));
  }
};

// Airline Admin Registration with Key
export const AirAdminSignupWithoutKey = (data) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.post('/airline-register', data);

    if (res) {
      dispatch(fetchSuccess());
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(fetchError());

    toast.error(err.response.data.title);
    console.log('error!!!!', err.response.data);
    // dispatch(fetchError(err.response.data));
  }
};

// Airline Agent Registration with Key
export const AirAgentSignupWithKey = (data) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.post('/airline/register', data);

    if (res) {
      dispatch(fetchSuccess());
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(fetchError());
    toast.error(err.response.data.title);
    console.log('error!!!!', err.response.data);
    // dispatch(fetchError(err.response.data));
  }
};

//  Shipper Registration
export const ShipperSignup = (data) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.post('/shipper/register', data);

    if (res) {
      dispatch(fetchSuccess());
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  } catch (err) {
    // console.log(err);
    toast.error(err?.response?.data?.title);
    // console.log('error!!!!', err.response.data);
    dispatch(fetchError());
    // dispatch(fetchError(err.response.data));
    return new Promise((resolve, reject) => {
      reject(err?.response);
    });
  }
};

// Trucking Admin Registration with Key
export const TruckingAdminSignupWithKey = (data) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.post('/trucking/register', data);

    if (res) {
      dispatch(fetchSuccess());
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.title);
    // console.log('error!!!!', err.response.data);
    // dispatch(fetchError(err.response.data));
  }
};

// Trucking Admin Registration with Key
export const TruckingAdminSignupWithoutKey = (data) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.post('/trucking-register', data);

    if (res) {
      dispatch(fetchSuccess());
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.title);
    console.log('error!!!!', err.response.data);
    // dispatch(fetchError(err.response.data));
  }
};

// user signin send request
export const onJwtSignIn = (credentials) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios({
        method: 'post',
        url: '/authenticate',
        data: {
          username: credentials.email,
          password: credentials.password,
        },
      });

      if (res.status === 400 || res.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
      } else {
        localStorage.setItem('token', res.data.jwtToken);
        localStorage.setItem('userDetails', JSON.stringify(res.data));
        dispatch(setJWTToken(res.data.jwtToken));
        dispatch(loadJWTUser(res.data));
        return new Promise((resolve, reject) => {
          resolve(res);
        });
      }
    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
      return new Promise((resolve, reject) => {
        reject(err.response);
      });
    }
  };
};

export const loadJWTUser = (data) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      dispatch(fetchSuccess());
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: {
          authType: AuthType.JWT_AUTH,
          displayName: `${data.firstName} ${data.lastName}`,
          email: data.email,
          role: data.role[0].authority,
          token: data.jwtToken,
          photoURL: data.avatar,
        },
      });
    } catch (err) {
      console.log('error!!!!', err.response.error);
      dispatch(fetchError(err.response.error));
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch(setLoggedOut());
    dispatch(saveQuotationDataReq(''));
    dispatch({
      type:GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD ,
      payload: [],
    });    setTimeout(() => {
      dispatch({ type: SIGNOUT_AUTH_SUCCESS });
      dispatch({ type: FETCH_SUCCESS });
      localStorage.removeItem('token');
      localStorage.removeItem('userDetails');
      // history.push('/home');
      // localStorage.removeItem('unloadTime');
    }, 500);
  };
};

export const getProfile = () => async (dispatch) => {
  try {
    dispatch({ type: GET_PROFILE_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get('/auth', config);

    return new Promise((resolve, reject) => {
      if (response.status === 200 && response.data !== '') {
        dispatch({ type: GET_PROFILE_SUCCESS, payload: response.data });
        if(response.data=='Airline Agent'||response.data=='Super Admin')
        {
    
        }else 
        {
          // dispatch(getPrefferedCurrencySelected(response?.data?.id))
          dispatch(getManaualSetting(response?.data?.companyId))
        }
        resolve(response);
      } else {
        dispatch({ type: GET_PROFILE_FAIL });
      }
    });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: GET_PROFILE_FAIL });
  }
};
